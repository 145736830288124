<template>
    <div>
        <input hidden type="text" class="form-control" id="Id" v-model="Id">
        <div class="modal fade" id="GeneralJournalStatusModal" tabindex="-1" aria-labelledby="GeneralJournalStatusModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="GeneralJournalStatusModalLabel" class="font-weight-bold">{{ModalTitle}}{{TransactionNumber}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                        </div>
                       
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {globalfunc} from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import generalJournalServices from '../Script/GeneralJournalService.js';
import { response } from '../../../../infrastructure/constant/response';
import { sweet_alert } from '../../../../infrastructure/constant/variable';

export default {
    name: 'GeneralJournalStatusForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',

            Id : '',
            TransactionNumber : '',
            Status : '',
            StatusData : [],
        }
    },
    methods: {
        async statusClick(journalGridData){
            this.ModalTitle = 'Edit Status Jurnal Umun: ';
            this.Id = journalGridData.id;
            this.TransactionNumber = journalGridData.transaction_number;
            
            this.StatusData = await globalfunc.globalDropdown('ddl_status_journal');
            this.Status = journalGridData.status;
            
            window.$('#GeneralJournalStatusModal').modal('show');
        },
        async saveClick(){
            var data = await generalJournalServices.getDetailJournal(this.Id);
            var journalDetail = globalfunc.objectToArrayConverter(data.journal_detail, 'Finance-JournalDetail');

            const journalData = {
                status: this.Status,
                region_id: data.region_id,
                transaction_date: data.transaction_date,
                transaction_number: data.transaction_number,
                contact_id: data.contact_id,
                desc: data.desc,
                notes: data.notes,
                new_journal_detail: journalDetail
            };
            
            const variables = {
                id : parseInt(this.Id),
                data : journalData
            }
            
            this.$swal(sweet_alert.update_status).then((result) => {
                if (result.isConfirmed == true) {
                    generalJournalServices.editJournal(variables).then(res => {
                        window.$('#GeneralJournalStatusModal').modal('hide');
                        this.$swal("Info", response.successUpdate, "success");
                        this.$props.reload();
                    }).catch(error => {
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>