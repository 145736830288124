<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true">
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import generalJournalServices from '../Script/GeneralJournalService.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'GeneralJournaldGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick', 'statusClick', 'pdfClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                page: 1,
                pageSize: 200,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging,
                                type : 1
                            }
                            return { query: generalJournalServices.readGeneralJournalQuery(),
                                        variables: variables};
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetJournal.journal == null){
                            return [];
                        }else{
                            return response.data.GetJournal.journal;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetJournal.total == null){
                            return 0;
                        }else{
                            return response.data.GetJournal.total;
                        }
                    },
                    model: {
                        fields: {
                            foreign_currency: {type: "number" },
                            transaction_date: {type: "date"},
                        }
                    }
                },
                sort: { field: "transaction_date", dir: "desc" },
                group: [{ field: "region"}, { field: "year"} , { field: "month_year"} ],
            }),
            columns:  [
                { title: "Action", width: 250, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} }, 
                { field: "region", title: "Region", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                    groupHeaderTemplate: "Region : #= value #", hidden: true},
                { field: "year", title: "Tahun", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                    groupHeaderTemplate: "Tahun : #= value #", hidden: true},
                { field: "month_year", title: "Bulan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                    groupHeaderTemplate: "Bulan : #= value #", hidden: true},
                { field: "region_name", title: "Region", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "transaction_number", title: "Nomor Transaksi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "transaction_date", title: "Tanggal Mulai", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: "#= kendo.toString(kendo.parseDate(transaction_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    } 
                },
                { field: "contact_name", title: "Kontak", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "balance", title: "Saldo", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}, format:"{0:N2}"}, 
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
        var grid = this.$refs.grid.kendoWidget();
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var statusClick = this.$props.statusClick;
        var pdfClick = this.$props.pdfClick;
       
        var GridElement = grid.element;

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.id);
        })

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.id, true);
        })
        
        ////Status Form
        GridElement.on("click", "#StatusButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            const journalData = {
                id : dataItem.id,
                transaction_number : dataItem.transaction_number,
                status : dataItem.status
            }
            
            statusClick(journalData);
        })
        
        GridElement.on("click", "#PdfButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            pdfClick(dataItem.id)
        })
    },
    methods: {
        columnButton(){
            var customButton = `&nbsp;&nbsp;
                                <button type="button" class="btn btn-secondary btn-sm rounded" id="PdfButton">
                                    <i class="fa fa-file-pdf-o"></i> </span>
                                </button>`;
            return this.$globalfunc.gridActionWithStatusChangesButton("Jurnal Umum", customButton);
        },
        changeGrid(status, region, month, year) {
            this.dataSource = new kendo.data.DataSource ({
                page: 1,
                pageSize: 200,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging,
                                type : 1,
                                status : status,
                                region : region,
                                month : month,
                                year : year
                            }
                            return { query: generalJournalServices.readGeneralJournalQuery(),
                                        variables: variables};
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetJournal.journal == null){
                            return [];
                        }else{
                            return response.data.GetJournal.journal;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetJournal.total == null){
                            return 0;
                        }else{
                            return response.data.GetJournal.total;
                        }
                    },
                    model: {
                        fields: {
                            foreign_currency: {type: "number" },
                            transaction_date: {type: "date"},
                        }
                    }
                },
                sort: { field: "transaction_date", dir: "desc" },
                group: [{ field: "region"}, { field: "year"} , { field: "month_year"} ],
            })
        }
    }
}
</script>